import HeaderStrip from './header-strip'
import Header from './header'
import './globals.css'
import Footer from './footer'
import FooterStrip from './footer_strip'
import { Outlet } from 'react-router-dom'
import WhatsAppIcon from '../components/wa_icon'
import { createContext, useMemo, useState } from 'react'

export const HeadingContext = createContext([]);

export const metadata = {
  title: 'Create Next App',
  description: 'Generated by create next app',
}

export default function RootLayout({ children }) {
  const [mainHeading, setMainHeading ]= useState('')
  return (
    <html lang="en">
      <head>
        <title>Yatloon</title>
      </head>
      <body className={`min-h-screen imprima`}>
        <HeaderStrip />
        <Header />
        <HeadingContext.Provider value={[mainHeading, setMainHeading]}>
          <Outlet />
        </HeadingContext.Provider>
        <Footer />
        <FooterStrip />
        <WhatsAppIcon />
        </body>
    </html>
  )
}
